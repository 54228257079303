<template>
  <div>
  <div class="row" v-if="loading_statistic">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error_statistic">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <div class="row">
          <div
            v-for="stat of statData"
            :key="stat.id"
            class="col-lg-6 col-sm-6 col-xl-4"
          >
            <Stat :icon="stat.icon" :tipo="stat.tipo" :title="stat.title" :value="stat.value" />
          </div>
        </div>
      </div>
      <div class="col-12">
      <div class="row" v-if="loading_saldo">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error_saldo">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
        <div class="row" v-else>
          <div
            v-for="stat of statDataFinanceiro"
            :key="stat.id"
            class="col-lg-6 col-sm-6 col-xl-4"
          >
            <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" :color="stat.color" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
       <div class="col-12">
        <div class="card">
          <GraficoVendas :dados="dados"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">
        <Estatisticas :dados="dados"/>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 mt-4 mt-xl-0">
        <div class="card text-left h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">Últimos Pedidos</h4>
            <Sales v-if="dados != null" :sales="dados.sales" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stat from '@/components/widgets/stat'
import Estatisticas from '@/components/widgets/estatisticas'
import GraficoVendas from '@/components/widgets/grafico_vendas'
import Sales from '@/components/widgets/sales'
import { mapActions } from 'vuex'
export default {
  components: {
    Stat,
    Sales,
    Estatisticas,
    GraficoVendas
  },
  data() {
    return {
      statData: [
        {
           id: 1,
          tipo: 0,
          icon: 'bx bx-shopping-bag',
          title: 'Vendas Último Mês',
          value: 0,
        },
        {
           id: 2,
          tipo: 0,
          icon: 'bx bx-shopping-bag',
          title: 'Vendas Totais',
          value: 0,
        },
        {
           id: 3,
          tipo: 1,
          icon: 'bx bx-user-circle',
          title: 'Clientes',
          value: 0,
        },
      ],
      statDataFinanceiro: [
        {
          id: 1,
          tipo: 0,
          icon: 'bx bx-wallet',
          title: 'Saldo Disponível',
          value: 0,
          color: 'bg-success',
        },
        {
          id: 2,
          tipo: 0,
          icon: 'bx bx-wallet',
          title: 'Saldo Bloqueado',
          value: 0,
          color: 'bg-danger',
        },
        {
          id: 3,
          tipo: 0,
          icon: 'bx bx-wallet',
          title: 'Saldo Total',
          value: 0,
          color: 'bg-success',
        },
      ],
      sales: [],
      loading_saldo: true,
      erorr_saldo: false,
      loading_statistic: true,
      erorr_statistic: false,
      dados: null
    }
  },
  created() {
     this.listarSaldo()
     this.listarStatistic()
  },
  methods: {
     ...mapActions('finance', ['ActionTransactionSaldo']),
     ...mapActions('home', ['ActionStatisticAll']),
      async listarStatistic(){
      try {
        this.loading_statistic = true
            await this.ActionStatisticAll().then(res=>{
              this.dados = res.data.data
              this.statData[0].value = res.data.data.sale_mes
              this.statData[1].value = res.data.data.sale_total
              this.statData[2].value = res.data.data.total_customer
              this.loading_statistic = false
              this.error_statistic = false
            })
        } catch (e) {
          this.loading_statistic = false
          this.error_statistic = false
        }
      },
     async listarSaldo(){
      try {
        this.loading_saldo = true
          await this.ActionTransactionSaldo().then(res=>{
            this.statDataFinanceiro[0].value = res.data.data.available
            this.statDataFinanceiro[1].value = res.data.data.blocked
            this.statDataFinanceiro[2].value = res.data.data.total
            this.loading_saldo = false
            this.error_saldo = false
          })
      } catch (_) {
         this.loading_saldo = false
         this.error_saldo = false
      }
    }
  },
}
</script>
