<template>
  <div class="card mini-stats-wid">
    <div class="card-body">
      <div class="media">
        <div class="media-body text-left">
          <p class="text-muted font-weight-medium text-left">{{title}}</p>
          <h4 class="mb-0 text-left" v-if="tipo == 0">{{value | currency('R$ ')}}</h4>
          <h4 class="mb-0 text-left" v-else>{{value}}</h4>
        </div>

        <div class="mini-stat-icon avatar-sm align-self-center rounded-circle" :class="color">
          <span class="avatar-title" :class="color">
            <i :class="`${icon} font-size-24`"></i>
          </span>
        </div>
      </div>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    color:{
      type: String,
      default: "bg-primary"
    },
    tipo:{
      type: Number,
      default: 0
    }
  }
};
</script>