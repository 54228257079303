<template>
  <div>
    <div class="card-body" v-if="dados == null">
      <div class="text-center text-info my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </div>

    <div class="card-body" v-else>
      <div class="d-flex flex-wrap">
        <h5 class="card-title mr-2">Análise de Vendas</h5>
        <!--<div class="ml-auto">
          <div class="toolbar button-items text-right">
            <div class="input-group input-group-sm">
                <select class="custom-select custom-select-sm">
                  <option selected>2021</option>
                  <option value="1">2020</option>
                  <option value="2">2019</option>
                  <option value="3">2018</option>
                </select>
                <div class="input-group-append">
                  <label class="input-group-text">ANO</label>
                </div>
              </div>
          </div>
        </div>-->
      </div>

      <div class="row text-center">
        <div class="col-lg-6 col-xl-3 col-md-12">
          <div class="mt-4">
            <p class="text-muted mb-1">Quantidade de Vendas</p>
            <h5>
              {{dados.total_sale}}
              <span class="font-size-13" :class="dados.total_sale_porc < 1 ? 'text-danger' : 'text-success'"> {{dados.total_sale_porc.toFixed(0)}}% <i class="mdi mdi-arrow-up ml-1 mr-1"></i>30 dias</span>
            </h5>
          </div>
        </div>

        <div class="col-lg-6 col-xl-3 col-md-12">
          <div class="mt-4">
            <p class="text-muted mb-1">Valor Total das Vendas</p>
            <h5>
              {{dados.sale_total | currency('R$ ')}}
              <span class="font-size-13" :class="dados.sale_total_porc < 1 ? 'text-danger' : 'text-success'"> {{dados.sale_total_porc.toFixed(0)}}% <i class="mdi mdi-arrow-up ml-1 mr-1"></i>30 dias</span>
            </h5>
          </div>
        </div>

        <div class="col-lg-6 col-xl-3 col-md-12">
          <div class="mt-4">
            <p class="text-muted mb-1">Ticket Médio</p>
            <h5>
              {{dados.ticket | currency('R$ ')}}
              <span class="font-size-13" :class="dados.ticket_porc < 1 ? 'text-danger' : 'text-success'"> {{dados.ticket_porc.toFixed(0)}}% <i class="mdi mdi-arrow-up ml-1 mr-1"></i>30 dias</span>
            </h5>
          </div>
        </div>

        <div class="col-lg-6 col-xl-3 col-md-12">
          <div class="mt-4">
            <p class="text-muted mb-1">Taxa de Conversão</p>
            <h5>
              {{dados.rate_conversion.toFixed(0)}}%
              <span class="font-size-13" :class="dados.rate_conversion_porc < 1 ? 'text-danger' : 'text-success'"> {{dados.rate_conversion_porc.toFixed(0)}}% <i class="mdi mdi-arrow-up ml-1 mr-1"></i>30 dias</span>
            </h5>
          </div>
        </div>
      </div>
      <hr class="mb-4" />
      <apexchart
        class="apex-charts"
        dir="ltr"
        height="350"
        :series="chartData.series"
        :options="chartData.chartoptions"
      ></apexchart>
    </div>
  </div>
</template>
<script>
export default {
 props: ['dados'],
 created(){
   this.dadosGrafico()
 },
  watch: {
    dados:function(){
       this.dadosGrafico()
    }
  },
  data() {
    return {
      chartData: {
        series: [
          {
            name: "Vendas",
            data: [],
          },
          {
            name: "Tráfego",
            data: [],
          },
        ],
        chartoptions: {
          chart: {
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          colors: ["#556ee6", "#f1b44c"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 100],
            },
          },
          xaxis: {
            categories: [],
          },
          markers: {
            size: 3,
            strokeWidth: 3,

            hover: {
              size: 4,
              sizeOffset: 2,
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
          },
        },
      },
    };
  },
  methods:{
    dadosGrafico(){
      if(this.dados != null){
          this.chartData.series[0].data = []
          this.chartData.chartoptions.xaxis.categories = []
          if(this.dados.sale && this.dados.sale != null){
            this.dados.sale.forEach(element => {
            this.chartData.series[0].data.push(element.value)
            this.chartData.chartoptions.xaxis.categories.push(element.month)
          });
          }
          this.chartData.series[1].data = []
          if(this.dados.rate && this.dados.rate != null){
            this.dados.rate.forEach(element => {
              this.chartData.series[1].data.push(element.value)
            });
          }
        }
    }
  }
};
</script>