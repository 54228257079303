<template>
  <div class="card h-100"  v-if="dados == null">
      <div class="card-body">
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
  </div>
  <div class="card h-100" v-else>
    <div class="card-header bg-transparent border-bottom text-left">
        <h4 class="card-title mb-4 mt-2">Atividades</h4>
      <div class="d-flex flex-wrap blog-card">
        <b-tabs
          pills
          nav-class="bg-light rounded card-header-tabs ml-auto"
          nav-wrapper-class="nav-item"
          align="left"
        >
          <b-tab title="+ Vistos">
            <div class="card-body mb-3 mt-2">
              <div data-simplebar style="max-height: 500px">
                <ul class="list-group list-group-flush" v-if="dados.products != null && dados.products.length > 0">
                  <li
                    class="list-group-item py-3"
                    v-for="data of dados.products"
                    :key="data.id"
                  >
                    <div class="d-flex">
                      <div class="mr-3" v-if="data.image != null && data.image.length > 0">
                      <span v-for="img in data.image" :key="img.id">
                        <img
                          :src="img.image300"
                          :alt="data.title"
                          v-if="img.capa == 1"
                          loading="lazy"
                          class="avatar-md h-auto d-block rounded"
                        />
                        </span>
                      </div>
                      <div class="mr-3" v-else>
                        <img
                          src="@/assets/images/notpro.jpg"
                          alt
                          loading="lazy"
                          class="avatar-md h-auto d-block rounded"
                        />
                      </div>
                      <div class="align-self-center overflow-hidden mr-auto">
                        <div>
                          <h5 class="font-size-14 text-truncate">
                            <a href="#" @click.prevent="$router.push({name: 'detalhes-do-produto', params: { id: data.id }, })" class="text-dark">{{ data.name }}</a>
                          </h5>
                          <p
                            class="text-muted mb-0"
                            v-if="
                              dataPromo(
                                data.discount_date_start,
                                data.discount_date_end,
                              )
                            "
                          >
                            <span
                              class="text-muted mr-2"
                              v-if="data.discount_price > 0"
                            >
                              <del>{{ data.price | currency('R$ ')}}</del>
                            </span>
                            <b>{{ data.discount_price | currency('R$ ') }}</b>
                          </p>
                          <p class="text-muted mb-0" v-else>
                            <b>{{ data.price | currency('R$ ') }}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div v-else class="text-center py-5">
                    <h5 class="mb-3">Nenhum item encontrado!</h5>
                    <img width="100" src="@/assets/images/not-data.svg" />
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Clientes">
            <div class="card-body mb-3 mt-2">
              <div data-simplebar style="max-height: 500px">
                <ul class="list-group list-group-flush" v-if="dados.customers != null && dados.customers.length > 0">
                  <li
                    class="list-group-item py-3"
                    v-for="data of dados.customers"
                    :key="data.id"
                  >
                    <div class="d-flex">
                      <div class="mr-3" v-if="data.avatar != null && data.avatar != ''">
                        <img
                          :src="data.avatar"
                          alt=""
                          class="avatar-sm d-block rounded-circle"
                        />
                      </div>
                      <div class="mr-3" v-else>
                        <img
                          src="@/assets/images/notpro.jpg"
                          :alt="data.name"
                          class="avatar-sm d-block rounded-circle"
                        />
                      </div>

                      <div class="align-self-center overflow-hidden mr-auto">
                        <div>
                          <h5 class="font-size-14 text-truncate">
                            <a href="#" class="text-dark">{{ data.name }}</a>
                          </h5>
                          <p class="text-muted mb-0">{{ data.created_at | moment('DD/MM/YYYY') }} às {{ data.created_at | moment('HH:mm') }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                 <div v-else class="text-center py-5">
                    <h5 class="mb-3">Nenhum item encontrado!</h5>
                    <img width="100" src="@/assets/images/not-data.svg" />
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Avaliações">
            <div class="card-body mb-3 mt-2">
              <div data-simplebar style="max-height: 500px">
                <ul class="list-group list-group-flush" v-if="dados.ratings != null && dados.ratings.length > 0">
                  <li
                    class="list-group-item py-3"
                    v-for="data of dados.ratings"
                    :key="data.id"
                  >
                    <div class="d-flex">
                      <div class="mr-3" v-if="data.product.image != null && data.product.image.length > 0">
                      <span v-for="img in data.product.image" :key="img.id">
                        <img
                          :src="img.image300"
                          v-if="img.capa == 1"
                          :alt="data.product.name"
                          loading="lazy"
                          class="avatar-md h-auto d-block rounded"
                        />
                        </span>
                      </div>
                      <div class="mr-3" v-else>
                        <img
                          src="@/assets/images/notpro.jpg"
                          alt
                          loading="lazy"
                          class="avatar-md h-auto d-block rounded"
                        />
                      </div>

                      <div class="align-self-center overflow-hidden mr-auto">
                        <div>
                          <h5 class="font-size-14 text-truncate">
                            <a href="#" @click.prevent="$router.push({name: 'detalhes-do-produto', params: { id: data.product.id }, })" class="text-dark">{{ data.product.name }}</a>
                          </h5>
                          <h6 class="font-size-12 text-truncate">
                            <a href="#" @click.prevent="$router.push({name: 'detalhe-cliente', params: { id: data.customer.id }, })" class="text-dark">{{ data.customer.name }}</a>
                          </h6>
                          <p class="text-muted mb-2">
                              <i
                                class="fas fa-star text-warning mr-1"
                                v-for="i in data.rating"
                                :key="i + Math.random(50000)"
                              ></i>
                              <i
                                class="fas fa-star text-muted mr-1"
                                v-for="i in (5 - data.rating)"
                                :key="i + Math.random(50000)"
                              ></i>
                          </p>
                           <p class="text-muted mb-0">{{ data.created_at | moment('DD/MM/YYYY') }} às {{ data.created_at | moment('HH:mm') }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                 <div v-else class="text-center py-5">
                    <h5 class="mb-3">Nenhum item encontrado!</h5>
                    <img width="100" src="@/assets/images/not-data.svg" />
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
   props: ['dados'],
  data() {
    return {
      postData: [],
      postDataCliente: [],
      postDataAvaliacoes: [],
    };
  },
  methods:{
    dataPromo(start, end) {
       var dia = new Date()
      var d = new Date(start)
      var df = new Date(end)
      if(d <= dia){
          if(dia <= df){
              return true
          }
          return false
      }
    },
  }
};
</script>